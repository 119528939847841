<template>
  <div>
    <StandardDialog
      v-model="servceListDialog"
      persistent
      :title="title"
      dialog-height="calc(90vh-1)"
      dialog-width="80vw"
    >
      <div style="height: 100%; overflow-y: hidden" ref="dataTableWrapper">
        <ServiceSelector
          style="width: 100%"
          mandatory
          @click="handleServiceSelection"
          show-frequent-services
        ></ServiceSelector>
      </div>

      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="close">Chiudi</v-btn>
      </template>
    </StandardDialog>
  </div>
</template>

<script>
import StandardDialog from "@/components/common/StandardDialog";
import ServiceSelector from '@/components/common/ServiceSelector.vue';
import servicesService from '@/services/services/services.service.js'

export default {
  name: "ServiceListDialog",
  components: {
    StandardDialog,
    ServiceSelector,
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Aggiungi servizi in fattura",
    },
  },
  mounted() {},

  data() {
    return {
      servceListDialog: this.openDialog,
    };
  },
  computed: {},
  methods: {
    close() {
      this.servceListDialog = false;
      this.$emit("close");
    },
    async handleServiceSelection(service) {
      let newService = await servicesService.get(service.id)
      this.$emit('add-service', newService)
    },
  },
  watch: {
    openDialog(newVal) {
      this.servceListDialog = newVal;
    },
  },
};
</script>

<style>
</style>