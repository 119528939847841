<template>
  <div>
    <v-row>
      <v-col 
        cols="12"
        sm="12"
        md="2"
        lg="2"
        xl="2"
      >
        <v-subheader
          class="font-italic text-h6 justify-center aling-center d-flex"
          >Riepilogo dei conti</v-subheader
        >
        <InvoiceSummary 
          :invoice="invoiceDynamic" 
          :variant="variant" 
          :vatPercentageEdit="vatPercentage" 
          :bus="invoiceSummaryBus"
          mode="edit"
        ></InvoiceSummary>
      </v-col>
      <v-divider
        v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
        class="ma-10" vertical
      ></v-divider>
      <v-col 
        cols="12"
        sm="12"
        md="9"
        lg="9"
        xl="9"
      >
        <v-subheader
          class="font-italic text-h6 justify-center aling-center d-flex"
          >Funzioni</v-subheader
        >
        <v-row>
          <v-col 
            cols="6"
            sm="3"
            md="3"
            lg="3"
            xl="3"
            class="d-flex justify-center align-center"
          >
            <v-card
              :disabled="disabledForm || canEdit"
              @click="openItemList"
              class="border-table justify-center aling-center"
              :width="$vuetify.breakpoint.sm ? '22vw' : 
                $vuetify.breakpoint.xs ? '40vw' : '15vw'"
              heigth="15vh"
            >
              <v-card-text class="d-flex justify-center aling-center"
                ><div class="d-flex justify-center aling-center">
                  <v-icon class="mr-2" large>mdi-warehouse</v-icon>
                  Aggiungi prodotto da magazzino
                </div></v-card-text
              >
            </v-card>
          </v-col>
          
          <v-col 
            cols="6"
            sm="3"
            md="3"
            lg="3"
            xl="3"
            class="d-flex justify-center align-center"
          >
            <v-card
              :disabled="disabledForm || canEdit"
              @click="openServiceInvoiceDialog"
              class="border-table justify-center aling-center"
              :width="$vuetify.breakpoint.sm ? '22vw' : 
                $vuetify.breakpoint.xs ? '40vw' : '15vw'"
              heigth="15vh"
            >
              <v-card-text class="d-flex justify-center aling-center"
                ><div class="d-flex justify-center aling-center">
                  <v-icon class="mr-2" large>mdi-text-box-plus-outline</v-icon>
                  Aggiungi servizi in fattura
                </div></v-card-text
              >
            </v-card>
          </v-col>
          
          <v-col 
            cols="6"
            sm="3"
            md="3"
            lg="3"
            xl="3"
            class="d-flex justify-center align-center"
          >
            <v-card
              :disabled="disabledForm || canEdit"
              @click="openGenericElementDialog"
              class="border-table justify-center aling-center"
              :width="$vuetify.breakpoint.sm ? '22vw' : 
                $vuetify.breakpoint.xs ? '40vw' : '15vw'"
              heigth="15vh"
            >
              <v-card-text class="d-flex justify-center aling-center"
                ><div class="d-flex justify-center aling-center">
                  <v-icon class="mr-2" large>mdi-text-box-plus-outline</v-icon>
                  Aggiungi elemento in fattura
                </div></v-card-text
              >
            </v-card>
          </v-col>
          
          <v-col 
            cols="6"
            sm="3"
            md="3"
            lg="3"
            xl="3"
            class="d-flex justify-center align-center"
          >
            <v-card
              :disabled="disabledForm || canEdit"
              @click="deleteInvoiceItem"
              class="border-table justify-center aling-center"
              :width="$vuetify.breakpoint.sm ? '22vw' : 
                $vuetify.breakpoint.xs ? '40vw' : '15vw'"
              heigth="15vh"
            >
              <v-card-text class="d-flex justify-center aling-center"
                ><div class="d-flex justify-center aling-center">
                  <v-icon class="mr-2" large
                    >mdi-minus-box-multiple-outline</v-icon
                  >
                  Rimouvi tutti gli elementi in fattura
                </div></v-card-text
              >
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div
              class="
                font-italic
                mb-2
                text-h6
                justify-center
                aling-center
                d-flex
              "
            >
              Riepilogo elementi
            </div>
            <div
              class="border-table"
              style="min-height: 50vh; max-height: 50vh; overflow-x: hidden"
              ref="dataTableWrapper"
            >
              <InvoiceItemDataTable
                v-model="selectedItems"
                @input="handleSelection"
                class=""
                :loading="loading"
                :items="invoiceElements"
                :headers="headerInvoiceItem"
                :select-on-row-click="false"
                :show-select="false"
                :show-actions="true"
                :page.sync="page"
                :row-per-page.sync="rowPerPage"
                :total-pages.sync="totalPages"
                :translator="translator"
                no-data-text="Selezionare fornitore per procedere"
              >
                <template @click.stop="" v-slot:actions="{ item }">
                  <v-btn :disabled="disabledForm" icon @click="addItem(item)">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="disabledForm"
                    icon
                    @click="removeItem(item)"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="disabledForm"
                    icon
                    @click="deleteItem(item)"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>

                <template v-slot:custom-quantity="{ item }">
                  <v-chip color="secondary">
                    {{ item["quantity"] ? item["quantity"] : 0 }}</v-chip
                  >
                </template>

                <template v-slot:custom-discount="{ item }">
                  <v-text-field
                    class="mt-3 mb-3"
                    :style="{'min-width': '120px'}"
                    type="number"
                    min="0"
                    max="100"
                    filled
                    flat
                    rounded
                    hide-details="auto"
                    v-model="item['discount']"
                    :rules="[isNumeric]"
                    :readonly="variant == 'detail' ? true : false"
                    @input="updateDiscount(item)"
                    @click:append-outer="reload"
                    append-outer-icon="mdi-reload"
                  ></v-text-field>
                </template>

                <template v-slot:custom-price="{ item }">
                  <v-chip color="primary">
                    € {{ item["price"] ? Number(item["price"]).toFixed(2) : 0 }}</v-chip
                  >
                </template>

                <template v-slot:custom-discountedPrice="{ item }">
                  <v-chip color="primary">
                    €
                    {{
                      item["price"] && item["discount"]
                        ? Number(
                            Number(item["price"]) *
                              ((100 - Number(item["discount"])) / 100)
                          ).toFixed(2)
                        : Number(item["price"]).toFixed(2)
                    }}</v-chip
                  >
                </template>

                <template v-slot:custom-priceTot="{ item }">
                  <v-chip color="primary">
                    €
                    {{
                      item["price"] && item["discount"] && item["quantity"]
                      ? Number(
                            Number(item["price"]) *
                              ((100 - Number(item["discount"])) / 100)
                              * item["quantity"]
                          ).toFixed(2)
                        : Number(item["price"] * item["quantity"]).toFixed(2)
                    }}</v-chip
                  >
                </template>
                <template v-slot:custom-taxable="{ item }">
                  <v-chip color="secondary">
                    €
                    {{
                      item["price"] && item["discount"] && item["quantity"]
                        ? Number(
                            Number(item["price"]) *
                              ((100 - Number(item["discount"])) / 100)
                              * item["quantity"]
                              / ((100 + item["vatPercentage"]) / 100)
                          ).toFixed(2)
                        : Number(Number(item["price"]) * Number(item["quantity"]) / ((100 + Number(item["vatPercentage"])) / 100)).toFixed(2)
                    }}</v-chip
                  >
                </template>
                <template v-slot:custom-vatPercentage="{ item }">
                  <TaxesListSelector
                    v-model="item.vatPercentage"
                    :readonly="variant == 'detail' ? true : false"
                    @change="updateIva(item);"
                  ></TaxesListSelector>
                </template>
                <template v-slot:custom-vat="{ item }">
                  <v-chip color="secondary">
                    € 
                    {{
                      item["price"] && item["discount"] && item["quantity"]
                        ? Number(
                            Number(item["price"]) *
                              ((100 - Number(item["discount"])) / 100)
                              * item["quantity"]
                              / ((100 + item["vatPercentage"]) / 100)
                              * (item["vatPercentage"] / 100)
                          ).toFixed(2)
                        : Number(item["price"] * item["quantity"] * (item["vatPercentage"] / 100) / ((100 + item["vatPercentage"]) / 100)).toFixed(2)
                    }}</v-chip
                  >
                </template>
                <template v-slot:custom-tag="{item}">
                  {{ item["tags"] ? (item["tags"][0].tagCategoryName + " - " + item["tags"][0].description): "Nessuna" }}
                </template>
              </InvoiceItemDataTable>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <ItemListDialog
      :open-dialog="openItemListDialog"
      @close="openItemListDialog = false"
      @add-items="handleAddItems"
      @error-quantity="handleError"
    >
    </ItemListDialog>
    <ServiceListDialog
      :open-dialog="openServiceListDialog"
      @close="openServiceListDialog = false"
      @add-service="handleAddService"
    >
    </ServiceListDialog>

    <GenericElementListDialog
      :open-dialog="openGenericElementListDialog"
      @close="openGenericElementListDialog = false"
      @add-element="handleAddGenericElement"
    >
    </GenericElementListDialog>

    <ErrorDialogComponent
      v-if="showErrorDialog"
      :open-dialog="showErrorDialog"
      title="Fattura"
      :error-message="errorMessage"
      type="error"
      @close-error="showErrorDialog = false"
    >
    </ErrorDialogComponent>
  </div>
</template>

<script>
import Vue from "vue";
import invoiceForm from "@/services/invoices/invoice.form.js";
import ErrorDialogComponent from "@/components/common/errorComponent/ErrorDialogComponent.vue";
import InvoiceItemDataTable from "@/components/common/TypeDataTable.vue";
import currentUser from "@/services/currentUser.service";
import ServiceListDialog from "@/components/areas/payments/invoices/components/ServiceListDialog.vue";
import GenericElementListDialog from "@/components/common/GenericElementListDialog.vue";
import invoiceService from "@/services/invoices/invoices.service.js";
import ItemListDialog from "@/components/areas/payments/invoices/components/ItemListDialog.vue";
import itemVocabulary from "@/vocabulary/warehouse/item.vocabulary.js";
import RadioGroupComponent from "@/components/common/RadioGroupComponent.vue";
import InvoiceSummary from "@/components/areas/payments/invoices/components/InvoiceSummary.vue";
import TaxesListSelector from "@/components/areas/payments/taxes/TaxesListSelector.vue"

export default {
  name: "InvoiceElementFormEditTab",
  components: {
    GenericElementListDialog,
    ServiceListDialog,
    InvoiceItemDataTable,
    RadioGroupComponent,
    ItemListDialog,
    ErrorDialogComponent,
    InvoiceSummary,
    TaxesListSelector
  },

  props: {
    bus: {
      type: Object,
      default: () => new Vue(),
    },
    variant: {
      type: String,
      default: "new",
      validator: function (value) {
        if (!["edit", "detail"].includes(value)) {
          return "invalid variant";
        } else {
          return true;
        }
      },
    },
  },
  data: function () {
    return {
      errorMessage: "Completare di inserire i campi di fatturazione",
      invoiceItemListTypes: [
        {
          key: 0,
          name: "Tabellare",
          value: "table",
        },
        {
          key: 1,
          name: "Lista",
          value: "card",
        },
      ],
      showErrorDialog: false,
      loading: false,
      formValid: false,
      invoiceElements: [],
      selectedItems: [],
      page: 1,
      rowPerPage: 50,
      totalPages: 1,
      dataTableHeightValue: 400,
      resizeListener: undefined,
      supplierSelected: undefined,
      customerSelected: undefined,
      suppliers: [],
      customers: [],
      invoiceTypeSelected: "customer",
      invoiceDepartmentSelected: "saloon",
      openItemListDialog: false,
      openServiceListDialog: false,
      openGenericElementListDialog: false,
      invoiceItemListTypeSelected: "table",
      disabledForm: false,
      vatPercentage: undefined,
      invoiceSummaryBus: new Vue(),
      headerInvoiceItem: [
        {
          text: "Descrizione",
          value: "description",
        },

        {
          text: "Prezzo listino",
          value: "price",
          type: "custom",
        },
        {
          text: "Sconto %",
          value: "discount",
          type: "custom",
        },
        {
          text: "Prezzo scontato",
          value: "discountedPrice",
          type: "custom",
        },
        {
          text: "N° pz",
          value: "quantity",
          type: "custom",
        },
        {
          text: "Subtotale",
          value: "priceTot",
          type: "custom",
        },
        {
          text: "Aliquota IVA",
          value: "vatPercentage",
          type: "custom",
        },
        
        {
          text: "Imponibile",
          value: "taxable",
          type: "custom",
        },
        {
          text: "IVA",
          value: "vat",
          type: "custom",
        },
        {
          text: "Tag",
          value: "tag",
          type: "custom",
        },
      ],
      taxForSaloon: undefined,
      qtyCheck: (qty) => {
        if (qty <= 0) {
          return itemVocabulary.Correct_Qty.desc;
        } else true;
      },
      presenceRule: (v) => {
        return !!v || "Il campo è obbligatorio";
      },
      max8CharRule: (v) => {
        if (v) {
          return v.length <= 8 || "Al massimo 8 caratteri";
        } else {
          return true;
        }
      },
      isNumeric: (v) => {
        return (Number(v) === 0 || (!!v && !isNaN(Number(v)))) || "Il valore deve essere un numero";
      },
      invoice: {},
    };
  },
  computed: {
    canEdit() {
      return this.invoice && this.invoice.type === "warehouse" ? true : false;
    },
    invoiceDynamic() {
      return {
        invoiceElements: this.invoiceElements,
      };
    },
  },
  mounted() {
    this.invoice = this.$attrs.invoice ? this.$attrs.invoice : {};
    if (!!this.$attrs.invoice) {
      this.$attrs.invoice.vatPercentage != undefined ? this.vatPercentage = Number(this.$attrs.invoice.vatPercentage) : this.vatPercentage = 0
      this.invoiceElements = this.calcInvoiceElements(this.$attrs.invoice);
      this.invoiceElements.sort((x, y) =>
        x.description.localeCompare(y.description)
      );
    } else this.bus.$emit("reload-invoice");
    if (this.$refs.dataTableWrapper) {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60;
      this.resizeListener = function () {
        this.dataTableHeightValue =
          this.$refs.dataTableWrapper.offsetHeight - 60;
      };
      this.resizeListener = this.resizeListener.bind(this);
      window.addEventListener("resize", this.resizeListener);
    }
    this.setUser();
    if (this.variant === "detail") this.disabledForm = true;
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.resizeListener);
  },
  methods: {
    async updateIva(invoiceElement){
      try {
        if (invoiceElement.type === "items") 
          await invoiceService.updateInvoiceItem(this.invoice, invoiceElement.invoiceItemId, invoiceElement);
        else if (invoiceElement.type === "services")
          await invoiceService.updateInvoiceService(this.invoice, invoiceElement.invoiceServiceId, invoiceElement); 
        else if (invoiceElement.type === "genericElements")
          await invoiceService.updateInvoiceGenericElement(this.invoice, invoiceElement.id, invoiceElement); 
        this.bus.$emit("reload-invoice");        
      } catch (error) {
        console.log(error)
      }
    },
    async updateDiscount(invoiceElement){
      try {
        if (invoiceElement.type === "items") 
          await invoiceService.updateInvoiceItem(this.invoice, invoiceElement.invoiceItemId, invoiceElement);
        else if (invoiceElement.type === "services")
          await invoiceService.updateInvoiceService(this.invoice, invoiceElement.invoiceServiceId, invoiceElement); 
        else if (invoiceElement.type === "genericElements")
          await invoiceService.updateInvoiceGenericElement(this.invoice, invoiceElement.id, invoiceElement); 
      } catch (error) {
        console.log(error)
      }
    },
    reload(){
      this.bus.$emit("reload-invoice");
    },
    addItem(invoiceElement) {
      if (invoiceElement.type === "items") this.handleAddItem(invoiceElement);
      else if (invoiceElement.type === "services")
        this.handleAddService(invoiceElement);
      else if (invoiceElement.type === "genericElements")
        this.handleAddGenericElement(invoiceElement);
    },
    removeItem(invoiceElement) {
      if (invoiceElement.type === "items")
        this.handleRemoveItem(invoiceElement);
      else if (invoiceElement.type === "services")
        this.handleRemoveService(invoiceElement);
      else if (invoiceElement.type === "genericElements")
        this.handleRemoveGenericElement(invoiceElement);
    },
    deleteItem(invoiceElement) {
      if (invoiceElement.type === "items")
        this.handleDeleteItem(invoiceElement);
      else if (invoiceElement.type === "services")
        this.handleDeleteService(invoiceElement);
      else if (invoiceElement.type === "genericElements")
        this.handleDeleteGenericElement(invoiceElement);
    },
    handleError(errorMessage) {
      this.showErrorDialog = true;
      this.errorMessage = errorMessage;
    },
    handleAddItems(items) {
      let invoiceItems = items.map((i) => {
        return {
          price: i.priceSelling,
          quantity: i.qtyToSell,
          id: i.id,
        };
      });
      invoiceService.addItems(this.invoice, invoiceItems).then((value) => {
        this.bus.$emit("reload-invoice");
        this.openItemListDialog = false;
      });
    },
    handleDeleteItem(item) {
      invoiceService.deleteItemFromInvoice(this.invoice, item).then((value) => {
        this.bus.$emit("reload-invoice");
      });
    },
    handleDeleteService(service) {
      invoiceService
        .deleteServiceFromInvoice(this.invoice, service)
        .then((value) => {
          this.bus.$emit("reload-invoice");
        });
    },
    handleDeleteGenericElement(genericElement) {
      invoiceService
        .deleteGenericElementFromInvoice(this.invoice, genericElement)
        .then((value) => {
          this.bus.$emit("reload-invoice");
        });
    },
    handleAddItem(item) {
      invoiceService.addItemToInvoice(this.invoice, item).then((value) => {
        this.bus.$emit("reload-invoice");
        
      });
    },
    handleAddService(service) {
      invoiceService
        .addServiceToInvoice(this.invoice, service)
        .then((value) => {
          this.bus.$emit("reload-invoice");
        });
    },
    handleAddGenericElement(genericElement) {
      invoiceService
        .addGenericElementToInvoice(this.invoice, genericElement)
        .then((value) => {
          this.bus.$emit("reload-invoice");
        });
    },

    handleRemoveItem(item) {
      invoiceService.removeItemFromInvoice(this.invoice, item).then((value) => {
        this.bus.$emit("reload-invoice");
      });
    },
    handleRemoveService(service) {
      invoiceService
        .removeServiceFromInvoice(this.invoice, service)
        .then((value) => {
          this.bus.$emit("reload-invoice");
        });
    },
    handleRemoveGenericElement(genericElement) {
      invoiceService
        .removeGenericElementFromInvoice(this.invoice, genericElement)
        .then((value) => {
          this.bus.$emit("reload-invoice");
        });
    },
    handleInvoiceItemListTypeSelected(value) {
      this.invoiceItemListTypeSelected = value;
    },
    openServiceInvoiceDialog() {
      this.openServiceListDialog = true;
    },
    openGenericElementDialog() {
      this.openGenericElementListDialog = true;
    },
    openItemList() {
      this.openItemListDialog = true;
    },
    deleteInvoiceItem() {
      invoiceService.deleteAllElements(this.invoice).then((value) => {
        this.bus.$emit("reload-invoice");
      });
    },
    translator(field, value) {
      if (field == "itemType" && value) {
        return value.name;
      } else if (field == "supplier" && value) {
        return value.businessName;
      }
    },
    handleSelection(itemsSelected) {
      this.selectedItems = itemsSelected;
    },
    setUser() {
      let userId = 0;
      currentUser.getUser().then((user) => {
        userId = user.id;
      });
    },
    updateObject(key, value) {
      invoiceForm.updateField(key, value);
    },
    calcInvoiceElements(invoice) {
      let items = [],
        services = [],
        genericElements = [];
      if (!!invoice) {
        items = invoice.invoiceItems
          ? invoice.invoiceItems.map((el) => {
              return {
                type: "items",
                invoiceItemId: el.id,
                description: el.item.description,
                quantity: Number(el.quantity),
                price: Number(el.price),
                discount: Number(el.discount),
                id: el.itemId,
                vatPercentage: el.vatPercentage != undefined ? Number(el.vatPercentage) : (this.taxForSaloon || 0),
                tags: el.item.tags,
              };
            })
          : [];
        services = invoice.invoiceServices
          ? invoice.invoiceServices.map((el) => {
              return {
                type: "services",
                invoiceServiceId: el.id,
                description: el.service.name,
                quantity: Number(el.quantity),
                price: Number(el.price),
                discount: Number(el.discount),
                id: el.serviceId,
                vatPercentage: el.vatPercentage != undefined ? Number(el.vatPercentage) : (this.taxForSaloon || 0),
                tags: el.service.tags,
              };
            })
          : [];
        genericElements = invoice.invoiceGenericElements
          ? invoice.invoiceGenericElements.map((el) => {
              return {
                type: "genericElements",
                description: el.description,
                quantity: Number(el.quantity),
                price: Number(el.price),
                discount: Number(el.discount),
                id: el.id,
                vatPercentage: el.vatPercentage != undefined ? Number(el.vatPercentage) : (this.taxForSaloon || 0),
                tags: el.tags,
              };
            })
          : [];
      }
      return [...items, ...services, ...genericElements];
    },
  },
  watch: {
    $attrs(newVal) {
      if (!!newVal.invoice) {
        this.invoiceElements = this.calcInvoiceElements(newVal.invoice);
        console.log("Invoice elements", this.invoiceElements)
        this.invoiceElements.sort((x, y) =>
          x.description.localeCompare(y.description)
        );
        this.invoice = newVal.invoice;
      } else this.invoice = {};
    },
    page() {
      this.fetchItems({});
    },
    rowPerPage() {
      this.fetchItems({});
    },
  },
  settings: {
    taxForSaloon: {
      bind: "taxForSaloon",
    },
  }
};
</script>
<style scoped>
.border-table {
  padding: 5px;
  border-radius: 25px;
  border: 1px solid #DCD8D8;
}
</style>