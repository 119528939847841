<template>
  <v-form v-model="formValid" ref="invoiceToCashForm">
    <StandardDialog
      v-model="supplierDataErrorDialog"
      :dialog-height="null"
      dialog-width="400px"
      title="Dati Azienda Mancanti"
      @close="supplierDataErrorDialog = false"
    >
    <v-alert 
      border="left"
      class="py-2 my-3"
      color="error"
    >
     {{supplierDataErrorMessage}}
    </v-alert> 
    </StandardDialog>
    <v-row v-if="status === 'cashed'">
      <div class="d-flex justify-center align-center my-2">
        <div class="d-flex justify-center align-center my-2">
          <AnimatedCheck class="mx-4" v-model="invoicePaid"></AnimatedCheck>
          <div class="text-h6" style="width: 500px; max-width: 90vw">
            {{ invoicePaidMessage }}
          </div>
        </div>
      </div>
    </v-row>
    <v-subheader class="font-italic font-weight-light py-1"
      >Generale</v-subheader
    >
    <v-row>
      <v-col
        cols="7"
        sm="4"
        md="2"
        lg="2"
        xl="2"
      >
        Codice Interno Fattura
        <v-text-field
          v-model="internalCode"
          flat
          rounded
          filled
          disabled
          prepend-icon="mdi-code-tags"
          hide-details="auto"
          @input="updateObject('internalCode', $event)"
        ></v-text-field>
      </v-col>
      <v-col
        cols="5"
        sm="2"
        md="1"
        lg="1"
        xl="1"
      >
        Progressivo
        <v-text-field
          v-model="code"
          flat
          rounded
          filled
          disabled
          hide-details="auto"
          @input="updateObject('code', $event)"
        ></v-text-field>
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="3"
        lg="3"
        xl="3"
      >
        Numero Fattura o Documento
        <v-text-field
          v-model="documentNumber"
          flat
          filled
          rounded
          label="Numero documento"
          hide-details="auto"
          @input="updateObject('documentNumber', $event)"
          :rules="[presenceRule]"
          :readonly=" (variant=='detail') && !editOptionByAdmin ? true : false"
        ></v-text-field>
      </v-col>
        <v-col v-if="status !== 'paid' && ['new'].includes(variant)">
          <SuppliersAutocomplete
            class="ma-5 pr-5"
            v-model="supplierSelected"
            :clearable="type === 'warehouse'"
            return-object
            :error="!supplierSelected"
            @input="handleSupplierChange"
            prepend-icon="mdi-domain"
            :append-outer-icon="!!supplierSelected ? 'mdi-pencil' : undefined"
            :auto-detect-new-supplier="true"
          ></SuppliersAutocomplete>
        </v-col>
        <v-col v-if="['edit', 'detail'].includes(variant)">
          <v-text-field
            class="ma-5"
            v-model="supplierNameToShow"
            v-if="invoiceHeadedToSelected === 'supplier'"
            :readonly=" (variant=='detail') && !editOptionByAdmin ? true : false"
            flat
            rounded
            filled
            label="Azienda"
            @input="updateObject('lastname', $event)"
            hide-details="auto"
          ></v-text-field> 
        </v-col>
        <v-col cols="3" v-else-if="type === 'warehouse'">
          <DetailOrder
            class="border-default"
            max-height="45vh"
            max-width="18vw"
            min-width="18vw"
            min-height="45vh"
            v-if="orderSelected"
            :current-order-id="orderSelected ? Number(orderSelected.id) : 0"
          >
          </DetailOrder>
        </v-col>
        <v-col cols="3" v-else-if="type == 'cashDesk' && !!billSelected">
          <DetailBill
            class="border-default"
            max-height="45vh"
            max-width="18vw"
            min-width="18vw"
            min-height="45vh"
            v-if="billSelected"
            :current-bill-id="billSelected ? Number(billSelected.id) : 0"
          >
          </DetailBill>
      </v-col>
      <v-col 
        cols="2"
        sm="1"
        md="2"
        lg="2"
        xl="2"
      >
        <v-switch
          color="red"
          v-model="ghost"
          @change="updateObject('ghost', $event)"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col 
            cols="6"
            sm="6"
            md="3"
            lg="3"
            xl="3"
          >
            Note / Descrizione Movimento
            <v-textarea
              v-model="description"
              flat
              rounded
              filled
              hide-details="auto"
              @input="updateObject('description', $event)"
              :rules="[presenceRule]"
              :readonly=" (variant=='detail') && !editOptionByAdmin ? true : false"
              rows="3"
            ></v-textarea>
          </v-col>
          <v-col
            cols="6"
            sm="6"
            md="3"
            lg="3"
            xl="3"
          >
            Documenti Collegati
            <v-text-field
              v-model="linkedDocuments"
              dense
              filled
              label="Documenti Collegati"
              hide-details="auto"
              rounded
              :readonly=" (variant=='detail') && !editOptionByAdmin ? true : false"
              flat
              @input="updateObject('linkedDocuments', $event)"
            ></v-text-field>
          </v-col> 
          <v-col 
            cols="6"
            sm="6"
            md="3"
            lg="3"
            xl="3"
          >
            {{ "Data Fattura" }}
            <div class="pr-5">
              <DatePickerCommon
                filled
                flat
                :styles="{
                  'pointer-events': ['new'].includes(variant) ? 'all' : 'none',
                  opacity: ['new'].includes(variant) ? '1' : '0.6',
                }"
                label="Data Fattura"
                v-model="emittedAt"
                @input="updateObject('emittedAt', $event)"
                :rules="[presenceRule]"
                :error="!emittedAt"
                :readonly=" (variant=='detail') && !editOptionByAdmin ? true : false"
              ></DatePickerCommon>
            </div>
          </v-col>
          <v-col 
            cols="6"
            sm="6"
            md="3"
            lg="3"
            xl="3"
          >
            Data Scadenza
            <div class="pr-5">
              <DatePickerCommon
                filled
                flat
                :styles="{
                  'pointer-events': ['new'].includes(variant) ? 'all' : 'none',
                  opacity: ['new'].includes(variant) ? '1' : '0.6',
                }"
                label="Data Scadenza"
                v-model="expirationDate"
                @input="updateObject('expirationDate', $event)"
                :rules="[presenceRule]"
                :error="!expirationDate"
                :readonly=" (variant=='detail') && !editOptionByAdmin ? true : false"
              ></DatePickerCommon>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="4"
          >
            Modalità Di Pagamento
            <v-select
              v-model="paymentTermId"
              :items="paymentTermsItems"
              item-value="id"
              item-text="description"
              label="Modalità Di Pagamento"
              filled
              flat
              dense
              rounded
              :readonly=" (variant=='detail') && !editOptionByAdmin ? true : false"
              hide-details="auto"
              :rules="[presenceRule]"
              prepend-icon="mdi-contactless-payment"
              @change="updateObject('paymentTermId', $event)"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="4"
          >
            Tipo Natura Esenzione IVA
            <v-select
              v-model="paymentTypeNatureExemptionId"
              :items="paymentTypeNatureExemptionItems"
              item-value="id"
              item-text="description"
              label="Tipo Natura Esenzione IVA"
              filled
              rounded
              flat
              dense
              :readonly=" (variant=='detail') && !editOptionByAdmin ? true : false"
              hide-details="auto"
              prepend-icon="mdi-contactless-payment"
              @change="updateObject('paymentTypeNatureExemptionId', $event)"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            Operatore
            <OperatorsAutocomplete
              v-model="operatorId"
              :multiple="false"
              @input="updateObject('operatorId', $event)"
              :rules="[presenceRule]"
              :readonly=" (variant=='detail') && !editOptionByAdmin ? true : false"
              flat
              dense
              rounded
            ></OperatorsAutocomplete>  
          </v-col> 
        </v-row>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col
        cols="6"
        sm="6"
        md="3"
        lg="3"
        xl="3"
      >
        Cognome o Ragione Sociale
        <v-text-field
          v-model="lastname"
          dense
          filled
          label="Cognome o Ragione Sociale"
          hide-details="auto"
          rounded
          :readonly="(variant=='detail') && !editOptionByAdmin ? true : false"
          flat
          @input="updateObject('lastname', $event)"
        ></v-text-field>
      </v-col>
      <v-col
        cols="6"
        sm="6"
        md="3"
        lg="3"
        xl="3"
      >
        Nome
        <v-text-field
          v-model="firstname"
          dense
          filled
          label="Nome"
          hide-details="auto"
          :readonly="(variant=='detail') && !editOptionByAdmin ? true : false"
          flat
          rounded
          @input="updateObject('firstname', $event)"
        ></v-text-field>
      </v-col>
      <v-col
        cols="6"
        sm="6"
        md="3"
        lg="3"
        xl="3"
      >
        Indirizzo
        <v-text-field
          v-model="address"
          dense
          filled
          label="Indirizzo"
          hide-details="auto"
          rounded
          :readonly="(variant=='detail') && !editOptionByAdmin ? true : false"
          flat
          @input="updateObject('address', $event)"
        ></v-text-field>
      </v-col>
      <v-col 
        cols="6"
        sm="6"
        md="1"
        lg="1"
        xl="1"
      >
        CAP
        <v-text-field
          v-model="cap"
          dense
          filled
          label="CAP"
          hide-details="auto"
          rounded
          :readonly="(variant=='detail') && !editOptionByAdmin ? true : false"
          flat
          @input="updateObject('cap', $event)"
        ></v-text-field>
      </v-col>
      <v-col 
        cols="6"
        sm="6"
        md="2"
        lg="2"
        xl="1"
      >
        Provincia
        <v-text-field
          v-model="province"
          dense
          filled
          label="Provincia"
          hide-details="auto"
          rounded
          maxlength="2"
          :readonly="(variant=='detail') && !editOptionByAdmin ? true : false"
          flat
          @input="updateObject('province', $event)"
        ></v-text-field>
      </v-col>
      <v-col
        cols="6"
        sm="6"
        md="3"
        lg="3"
        xl="2"
      >
        Città
        <v-text-field
          v-model="city"
          dense
          filled
          label="Città"
          hide-details="auto"
          rounded
          :readonly="(variant=='detail') && !editOptionByAdmin ? true : false"
          flat
          @input="updateObject('city', $event)"
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col
        cols="6"
        sm="6"
        md="3"
        lg="3"
        xl="3"
      >
        Codice fiscale
        <v-text-field
          v-model="fiscalCode"
          dense
          filled
          label="Codice Fiscale"
          hide-details="auto"
          rounded
          :readonly="(variant=='detail') && !editOptionByAdmin ? true : false"
          flat
          @input="updateObject('fiscalCode', $event)"
        ></v-text-field>
      </v-col>
      
      <v-col
        cols="6"
        sm="6"
        md="3"
        lg="3"
        xl="3"
      >
        Codice destinatario
        <v-text-field
          v-model="destinataryCode"
          dense
          filled
          label="Codice Fiscale"
          hide-details="auto"
          rounded
          :readonly="(variant=='detail') && !editOptionByAdmin ? true : false"
          flat
          @input="updateObject('destinataryCode', $event)"
        ></v-text-field>
      </v-col>
      
      <v-col
        cols="6"
        sm="6"
        md="3"
        lg="3"
        xl="3"
      >
        Partita IVA
        <v-text-field
          v-model="vatNumber"
          dense
          filled
          label="Partita IVA"
          hide-details="auto"
          rounded
          :readonly="(variant=='detail') && !editOptionByAdmin ? true : false"
          flat
          @input="updateObject('vatNumber', $event)"
        ></v-text-field>
      </v-col>
      
      <v-col
        cols="6"
        sm="6"
        md="3"
        lg="3"
        xl="3"
      >
        PEC
        <v-text-field
          v-model="mail"
          dense
          filled
          label="PEC"
          hide-details="auto"
          rounded
          :readonly="(variant=='detail') && !editOptionByAdmin ? true : false"
          flat
          @input="updateObject('mail', $event)"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from "vue";
import invoiceForm from "@/services/invoices/invoice.form.js";
import { DateTimePicker } from "likablehair-ui-components";
import AnimatedCheck from "@/components/common/AnimatedCheck.vue";

import CustomersAutocomplete from "@/components/common/CustomersAutocomplete.vue";
import TagsAutocomplete from "@/components/common/TagsAutocomplete.vue";
import OperatorsAutocomplete from "@/components/common/OperatorsAutocomplete.vue";
import SuppliersAutocomplete from "@/components/common/SuppliersAutocomplete.vue";
import RadioGroupComponent from "@/components/common/RadioGroupComponent.vue";
import DetailOrder from "@/components/areas/payments/invoices/components/DetailOrder.vue";
import DetailBill from "@/components/areas/payments/invoices/components/DetailBill.vue";
import TaxesListSelector from "@/components/areas/payments/taxes/TaxesListSelector.vue"

import invoiceService from "@/services/invoices/invoices.service.js";
import dataInvoiceService from "@/services/invoices/dataInvoices.service.js";
import paymentTermService from "@/services/paymentTerms/paymentTerms.service.js";
import paymentConditionService from "@/services/paymentTerms/paymentConditions.service.js";
import paymentTypeNatureExemptionService from "@/services/paymentTerms/paymentTypeNatureExemptions.service.js";
import StandardDialog from "@/components/common/StandardDialog.vue"
import DatePickerCommon from '@/components/common/DatePickerCommon.vue'



export default {
  name: "GeneralFormTab",
  components: {
    DateTimePicker,
    CustomersAutocomplete,
    RadioGroupComponent,
    SuppliersAutocomplete,
    DetailOrder,
    DetailBill,
    AnimatedCheck,
    DatePickerCommon,
    TaxesListSelector,
    TagsAutocomplete,
    OperatorsAutocomplete,
    StandardDialog,
  },
  data: function () {
    return {
      amount: undefined,
      vatAmount: undefined,
      taxable: undefined,
      type: undefined,
      oldType: undefined,
      emittedAt: undefined,
      status: "",
      ghost: undefined,
      lastname: undefined,
      description: undefined,
      firstname: undefined,
      vatPercentage: undefined,
      address: undefined,
      cap: undefined,
      province: undefined,
      city: undefined,
      fiscalCode: undefined,
      mail: undefined,
      destinataryCode: undefined,
      firstname: undefined,
      vatNumber: undefined,
      id: undefined,
      code: 0,
      internalCode: "",
      expirationDate: undefined,
      documentNumber: undefined,
      operatorId: null,
      supplierId: null,
      linkedDocuments: undefined,
      supplierDataErrorDialog: false,
      supplierDataErrorMessage: "Assicurarsi che l'Azienda selezionata abbia i campi: Partita IVA, Indirizzo, Codice Destinatario, Mail e Nome Salvati!",

      invoiceTypes: invoiceService.listType(),
      loadingInvoice: false,
      formValid: false,
      typeCashDesk: "cashDesk",
      supplierSelected: null,
      customerSelected: null,
      suppliers: [],
      customers: [],
      orderSelected: [],
      billSelected: [],
      invoiceHeadedToSelected: "",
      invoiceTypeSelected: "",
      disabledForm: false,
      invoicePaid: false,
      invoicePaidMessage: "",
      paymentTypeNatureExemptionId: undefined,
      paymentTypeNatureExemptionItems: [],
      paymentTermId: undefined,
      paymentTermsItems: [],
      supplierNameToShow: undefined,
      max255CharRule: (v) => {
        if (v) {
          return v.length <= 255 || "Al massimo 255 caratteri";
        } else {
          return true;
        }
      },
      presenceRule: (v) => {
        return !!v || "Il campo è obbligatorio";
      },
    };
  },
  props: {
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
    variant: {
      type: String,
      default: "new",
      validator: function (value) {
        if (!["new", "edit", "detail"].includes(value)) {
          return "invalid variant";
        } else {
          return true;
        }
      },
    },
    editOptionByAdmin: {
      type: Boolean,
      default: false
    },
  },
  mounted: function () {
    var self = this;
    invoiceForm.on("update", function (data) {
      self.handleObjectChanges(data.invoice);
    });
    this.lastInternalCode();
    this.handleInvoiceHeadedToSelected("supplier");
    this.fetchPaymentTerms();

    this.handleInvoiceTypeSelected();
    this.updateObject('type','otherToCash')
    this.lastCode('otherToCash')
    if (this.variant === "detail") {
      this.disabledForm = true;
    }

    this.$refs.invoiceToCashForm.validate()
  },
  methods: {
    fetchPaymentTerms() {
      new Promise(async (resolve, reject) => {
        let resultPaymentTermsItems = await paymentTermService.list();
        this.paymentTermsItems = [...resultPaymentTermsItems.rows];

        let resultPaymentConditionItems = await paymentConditionService.list();
        this.paymentConditionItems = [...resultPaymentConditionItems.rows];

        let resultPaymentTypeNatureExemptionItems =
          await paymentTypeNatureExemptionService.list();
        this.paymentTypeNatureExemptionItems = [
          ...resultPaymentTypeNatureExemptionItems.rows,
        ];

        this.docPrintItems = dataInvoiceService.listDocPrint();
        resolve();
      });
    },
    showCheckPaid() {
      this.invoicePaid = this.status === "cashed" ? true : false;
      this.invoicePaidMessage = "Fattura incassata correttamente"
    },
    lastInternalCode() {
      invoiceService.getLastInvoice().then((lastInvoice) => {
        let progressiveInCode;
        let today = new Date();
        if (
          !!lastInvoice &&
          Number(lastInvoice.createdAt.substring(0, 4)) == today.getFullYear()
        ) {
          progressiveInCode = (Number(lastInvoice.progressive) + 1).toString();
          while (progressiveInCode.length < 5) {
            progressiveInCode = "0" + progressiveInCode;
          }
        } else {
          progressiveInCode = "00001";
        }
        this.internalCode =
          "FAT" + today.getFullYear() + "-" + progressiveInCode;
      });
    },
    lastCode(type) {
      invoiceService.getLastCode(type).then((lastCode) => {
        if (!!lastCode && this.variant == 'new')
          this.code = !!lastCode ? Number(lastCode) + 1 : Number(lastCode);
        else if(!!lastCode && this.variant != 'new')
          this.code = lastCode
        else 
          this.code = 1;
        this.updateObject("code", this.code);
      });
    },
    handleInvoiceHeadedToSelected(value) {
      this.invoiceHeadedToSelected = value;
    },
    handleInvoiceTypeSelected(value) {
      this.lastCode(value);
      this.invoiceTypeSelected = value;
      this.updateObject("type", value);
    },
    handleSupplierChange(supplierSelected) {
      if(!!supplierSelected){
        if(!supplierSelected.id || !supplierSelected.VATNumber || !supplierSelected.address || !supplierSelected.businessName || !supplierSelected.email || !supplierSelected.dataInvoice.destinataryCode){
          this.supplierSelected = undefined
          this.supplierDataErrorDialog = true
        }
        else{
          if(!!supplierSelected.paymentMethods && supplierSelected.paymentMethods.length > 0){
            switch (supplierSelected.paymentMethods[0]){
              case "cash":
                this.paymentTermId = 1;
                break;
              case "check":
                this.paymentTermId = 2;
                break;
              case "bankTransaction":
                this.paymentTermId = 4;
                break;
              default:
                this.paymentTermId = 4;
                break;  
            }
          }
          invoiceForm.updateFields(['supplierId', 'vatNumber', 'address', 'lastname', 'mail', 'destinataryCode', 'paymentTermId'], 
          [supplierSelected.id, supplierSelected.VATNumber, supplierSelected.address, supplierSelected.businessName, supplierSelected.email, supplierSelected.dataInvoice.destinataryCode, this.paymentTermId])
          this.bus.$emit("change-suppplier", supplierSelected)
        }
      }
    },
    fields() {
      return [
        "lastname",
        "description",
        "amount",
        "type",
        "firstname",
        "vatPercentage",
        "internalCode",
        "code",
        "status",
        "id",
        "operatorId",
        "taxable",
        "vatAmount",
        "documentNumber",
        "linkedDocuments",
        "address",
        "cap",
        "province",
        "city",
        "fiscalCode",
        "mail",
        "destinataryCode",
        "firstname",
        "vatNumber",
        "ghost"
      ];
    },
    handleObjectChanges(invoice) {
      const fields = this.fields();
      const newValKeys = Object.keys(invoice);

      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];

        if (newValKeys.includes(field) && invoice[field] != this[field]) {
          this[field] = invoice[field];
        }
      }
      if (!!invoice.emittedAt) {
        this.emittedAt = new Date(invoice.emittedAt);
      }
      if (!!invoice.expirationDate) {
        this.expirationDate = new Date(invoice.expirationDate);
      }
      if (!!invoice.vatPercentage) {
        this.vatPercentage = Number(invoice.vatPercentage);
      }
      if (!!invoice.paymentTermId) {
        this.paymentTermId = Number(invoice.paymentTermId);
      }
      if (!!invoice.paymentTypeNatureExemptionId) {
        this.paymentTypeNatureExemptionId = Number(invoice.paymentTypeNatureExemptionId);
      }
      if(!!invoice.lastname && !this.supplierSelected){
        this.supplierNameToShow = invoice.lastname
      }
      if(!invoice.invoiceSupplier){
        this.supplierNameToShow = invoice.lastname
      }
      this.showCheckPaid();
    },
    updateObject(key, value) {
      if (key == "type" && !this.oldType) {
        this.oldType = value;
      }
      invoiceForm.updateField(key, value);
    },
  },
  watch: {
    $attrs(newVal) {
      if (!!newVal.invoice) {
        if (
          !!newVal.invoice.invoiceOrder &&
          !!newVal.invoice.invoiceOrder.order
        )
          this.orderSelected = newVal.invoice.invoiceOrder.order[0];
        else if (!!newVal.invoice.bills && !!newVal.invoice.bills[0])
          this.billSelected = newVal.invoice.bills[0];
        if (
          !!newVal.invoice.invoiceSupplier &&
          !!newVal.invoice.invoiceSupplier.supplier
        )
          this.handleInvoiceHeadedToSelected("supplier");
        else if (
          !!newVal.invoice.invoiceCustomer &&
          !!newVal.invoice.invoiceCustomer.customer
        )
          this.handleInvoiceHeadedToSelected("customer");
        if (!["warehouse", "cashDesk"].includes(newVal.invoice.type))
          this.handleInvoiceTypeSelected(newVal.invoice.type);
        else {
          this.code = newVal.invoice.code;
        }
      }
    },
    formValid(newVal) {
      this.$emit("update:valid", newVal);
      if (this.bus) {
        this.bus.$emit("update:valid", newVal);
      }
      invoiceForm.setValid(newVal);
    },
    vatPercentage(newVal){
      if(newVal != undefined && !!this.taxable){
        this.amount = (Number(this.taxable) * (100 + Number(newVal))/100).toFixed(2)
        this.vatAmount = Number(this.amount - this.taxable).toFixed(2)
        invoiceForm.updateFields(['vatPercentage','taxable','vatAmount','amount'], [newVal,this.taxable,this.vatAmount,this.amount])
      }
    },
    taxable(newVal){
      if(!!newVal && this.vatPercentage != undefined){
        this.amount = (Number(newVal) * (100 + Number(this.vatPercentage))/100).toFixed(2)
        this.vatAmount = Number(this.amount - newVal).toFixed(2)
        invoiceForm.updateFields(['vatPercentage','taxable','vatAmount','amount'], [this.vatPercentage,newVal,this.vatAmount,this.amount])
      }
    }
  },
};
</script>
<style scoped>
.border-default {
  padding: 5px;
  border-radius: 25px;
  border: 1px solid #dcd8d8;
  transition: all 0.2s;
  transform: scale(1);
}
</style>